<template>
  <div>
    <b-card>
      <b-input-group class="mb-3">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="goods_no, goods_id, sku_id를 넣어주세요" v-model="form.search" @keypress.enter.prevent.stop="list" v-focus></b-form-input>
      </b-input-group>
      <template v-if="$R(['BALAANEER', 'E_GOODS_R']) || $S.user.group.includes('operator')">
        <shop-preset v-model="form.shop" v-bind="{shopPresetOption}"></shop-preset>
      </template>

      <b-row class="mb-2 p-1">
        <b-col cols="6" lg="4">
          <div><small class="mb-n2">정렬순서</small></div>
          <b-form-radio-group class="col-form-label" v-model="form.orderby" :options="[
            {text: '정렬없음', value: 'no_order'},
            {text: '발란코드', value: 'goods_no'},
            {text: '높은가격', value: 'price_high'},
            {text: '낮은가격', value: 'price_low'},
          ]"></b-form-radio-group>
      </b-col>
        <b-col cols="6" lg="4">
          <div><small>B최저가 기준<i class="fa fa-question-circle ml-1" v-b-tooltip="'발란회원가(Confirmed Price 기준으로 필터링됩니다.'"></i></small></div>

          <!--          <div><small class="mb-n2">B최저가 달성 여부</small></div>-->
<!--          <b-form-radio-group class="col-form-label" v-model="form.isBPrice" :options="[-->
<!--            {text: '전체', value: 'ALL'},-->
<!--            {text: 'Y', value: true},-->
<!--            {text: 'N', value: false},-->
<!--          ]"></b-form-radio-group>-->
          <b-form class="mt-1" inline>
            <div class="bold">B 최저가</div> 보다 &nbsp;
            <b-input class="w-50px" placeholder="" v-model.number="form.b_price_diff_per_from" autocomplete="off"></b-input>
            &nbsp;% 이상&nbsp;
            <b-input class="w-50px" placeholder="" v-model.number="form.b_price_diff_per_to" autocomplete="off"></b-input>
            &nbsp;% 이하 차이나는 상품
          </b-form>
        </b-col>
      </b-row>

      <b-collapse id="collapse1" v-model="collapse.detail">
        <b-row class="mb-2">
          <b-col cols="12" lg="3">
            <div><small class="mb-n2">연동형태</small></div>
            <b-form-radio-group class="col-form-label" v-model="form.manual" :options="[
              {text: '전체', value: 'ALL'},
              {text: '자동(FEED)', value: 'auto'},
              {text: '파트너관리', value: 'manual'}
            ]"></b-form-radio-group>
          </b-col>
          <b-col cols="12" lg="6">
            <div><small class="mb-n2">상품유형</small></div>
            <b-form inline>
              <b-form-radio-group class="col-form-label" v-model="form.goodsType" :options="[
                {text: '전체', value: 'ALL'},
                {text: '새상품만', value: 'new'},
                {text: '빈티지만', value: 'used'}
              ]"></b-form-radio-group>
              <template v-if="form.goodsType === 'used'">
                <b-button class="mr-1" size="sm" variant="light" @click="toggleUsedGrade()">전체</b-button>
                <b-button class="mr-1" size="sm" variant="primary" @click="toggleUsedGrade('S')">S</b-button>
                <b-button class="mr-1" size="sm" variant="success" @click="toggleUsedGrade('A')">A</b-button>
                <b-button class="mr-2" size="sm" variant="warning" @click="toggleUsedGrade('B')">B</b-button>
                <b-form-checkbox-group v-model="form.usedGrade">
                  <b-form-checkbox v-for="s in $C.USED_GRADE" :key="s.value" :value="s.value" :title="s.desc">{{s.text}}</b-form-checkbox>
                </b-form-checkbox-group>
              </template>
            </b-form>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="12" lg="6">
            <brand-preset class="mb-3" v-model="form.brand" :hideDisabled="true"></brand-preset>
          </b-col>
          <b-col cols="12" lg="6">
            <category-preset class="mb-3" v-model="form.category"></category-preset>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col lg="2">
            <small>가격조건</small><br/>
            <b-input-group>
              <b-form-input type="number" placeholder="최소가" v-model.number="form.min"></b-form-input>
              <b-input-group-append>
                <b-button variant="primary"><i class="fa fa-exchange"></i></b-button>
              </b-input-group-append>
              <b-form-input type="number" placeholder="최대가" v-model.number="form.max"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col md="2">
            <small>SKU ID 중간검색(다른 조건과 함께 사용해주세요)</small>
            <b-form-input type="text" v-model="form.sku_id"></b-form-input>
          </b-col>
          <b-col md="2">
            <small>총 재고합이 n 개 이상</small>
            <b-form-input type="text" placeholder="전체 재고가 이 숫자 이상입니다" v-model.number="form.tot_stock"></b-form-input>
          </b-col>
          <b-col md="2">
            <small>재고가 n 개 이상인 옵션이 존재</small>
            <b-form-input type="text" placeholder="options 의 재고가 n개 이상인 옵션이 존재합니다" v-model.number="form.opt_stock"></b-form-input>
          </b-col>
          <b-col md="2">
            <small>goods_no 범위선택</small><br/>
            <b-form inline>
              <b-form-input class="text-center w-100px" v-model.number="form.goods_no_from" placeholder="시작"></b-form-input>
              ~
              <b-form-input class="text-center w-100px" v-model.number="form.goods_no_to" placeholder="끝"></b-form-input>
            </b-form>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col md="3">
            <small>생성시각</small><br/>
            <b-form inline>
              <b-form-input class="text-center" v-model="form.created_from" placeholder="2020-04-01 00:00:00"></b-form-input>
              ~
              <b-form-input class="text-center" v-model="form.created_to" placeholder="2020-04-01 00:00:00"></b-form-input>
            </b-form>
          </b-col>
          <b-col md="3">
            <small>최근수정시각</small><br/>
            <b-form inline>
              <b-form-input class="text-center" v-model="form.updated_from" placeholder="2020-04-01 00:00:00"></b-form-input>
              ~
              <b-form-input class="text-center" v-model="form.updated_to" placeholder="2020-04-01 00:00:00"></b-form-input>
            </b-form>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col md="2">
            <small class="text-danger">오늘도착</small><br/>
            <b-form-radio-group class="col-form-label" v-model="form.oneday_delivery" :options="[
                {text: '전체', value: 'ALL'},
                {text: '있음', value: 'y'},
              ]"></b-form-radio-group>
          </b-col>
          <b-col md="2">
            <small class="text-success">오늘출발</small><br/>
            <b-form-radio-group class="col-form-label" v-model="form.today_pick" :options="[
                {text: '전체', value: 'ALL'},
                {text: '있음', value: 'y'},
              ]"></b-form-radio-group>
          </b-col>
          <b-col md="2">
            <small>주문제작</small><br/>
            <b-form-radio-group class="col-form-label" v-model="form.orderMade" :options="[
                {text: '전체', value: 'ALL'},
                {text: '있음', value: 'y'},
              ]"></b-form-radio-group>
          </b-col>
          <b-col md="2">
            <small>배송방식</small><br/>
            <b-form-radio-group class="col-form-label" v-model="form.deliveryExtra" :options="$F.DELIVERY_EXTRA_FILTER"></b-form-radio-group>
          </b-col>
          <b-col md="2">
            <small>배송유형</small><br/>
            <b-form-radio-group class="col-form-label" v-model="form.delivery_type" :options="[
                {text: '전체', value: 'ALL'},
                {text: '해외', value: '해외'},
                {text: '국내', value: '국내'},
              ]"></b-form-radio-group>
          </b-col>
          <b-col md="2">
            <small>이미지체크</small><br/>
            <b-form-radio-group class="col-form-label" v-model="form.image_check" :options="[
                {text: '전체', value: 'ALL'},
                {text: '이미지없음', value: 'n'},
              ]"></b-form-radio-group>
          </b-col>
        </b-row>

<!--        <b-row class="mb-3">-->
<!--          <b-col md="3">-->
<!--            <small>Naver 최저가 검색결과(6시간 이내 데이터)</small><br/>-->
<!--            <div class="col-form-label">-->
<!--              <b-form-checkbox v-model="form.has_naver">Naver 최저가 검색결과 존재</b-form-checkbox>-->
<!--            </div>-->
<!--          </b-col>-->
<!--          <b-col md="3" v-if="form.has_naver">-->
<!--            <small>Naver 최저가 랭킹(1 이면 최저가)</small><br/>-->
<!--            <b-form inline>-->
<!--              <b-input-group>-->
<!--                <b-form-input class="text-center w-50px" v-model.number="form.naver_rank_from"></b-form-input>-->
<!--                <b-input-group-append>-->
<!--                  <b-input-group-text>~</b-input-group-text>-->
<!--                </b-input-group-append>-->
<!--                <b-form-input class="text-center w-50px" v-model.number="form.naver_rank_to"></b-form-input>-->
<!--              </b-input-group>-->
<!--              <b-form-checkbox class="ml-2" v-model="form.naver_has_other_cnt">타사 상품 존재</b-form-checkbox>-->
<!--              <template v-if="form.naver_has_other_cnt">-->
<!--                <b-form-input class="text-center w-50px mx-2" v-model.number="form.naver_other_cnt"></b-form-input>-->
<!--                개 이상-->
<!--              </template>-->
<!--            </b-form>-->
<!--          </b-col>-->
<!--          <b-col md="4" v-if="form.has_naver">-->
<!--            <small>Naver 가격차이(%) 범위 선택(음수일 경우 발란 상품이 비싼 케이스)</small><br/>-->
<!--            <b-form inline>-->
<!--              <b-input-group>-->
<!--                <b-form-input class="text-right w-65px" placeholder="%" v-model.number="form.naver_price_diff_per_from"></b-form-input>-->
<!--                <b-input-group-append>-->
<!--                  <b-input-group-text>~</b-input-group-text>-->
<!--                </b-input-group-append>-->
<!--                <b-form-input class="text-right w-65px" placeholder="%" v-model.number="form.naver_price_diff_per_to"></b-form-input>-->
<!--              </b-input-group>-->
<!--            </b-form>-->
<!--          </b-col>-->
<!--        </b-row>-->

        <b-row class="mb-3">
          <b-col md="3">
            <small>옵션검색(검색엔진 이용, 싱크가 안맞을 수 있습니다)</small><br/>
            <div class="col-form-label">
              <b-form-checkbox v-model="form.options">옵션검색</b-form-checkbox>
            </div>
          </b-col>
          <b-col md="3" v-if="form.options">
            <small>옵션 Size</small><br/>
            <b-form inline>
              <b-form-input class="w-100px mr-2" name="optionSize" placeholder="ex) XXL" v-model="form.optionSize"></b-form-input>
            </b-form>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col>
            <small>컬러</small><br/>
            <div class="clearfix">
              <b-button class="pull-left mr-2" size="sm" variant="primary" @click="()=>{ if (form.major_color.length === $C.COLORS.length) form.major_color = []; else form.major_color = $C.COLORS.map(e=>e.name); }">전체</b-button>
              <b-form-checkbox-group class="pull-left mt-1" name="group" v-model="form.major_color">
                <b-form-checkbox v-for="c in $C.COLORS" :key="c.name" :value="c.name">
                  <div class="d-inline-block" :style="{backgroundColor:'#'+c.color, width:'22px', height:'22px'}" v-b-tooltip="c.name_ko"></div>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </div>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col lg="3">
            <small>goods_no</small>
            <b-form-textarea id="goodsNo" :rows="2" v-model="form.goods_no" placeholder="goods_no를 입력해주세요"></b-form-textarea>
          </b-col>
          <b-col lg="3">
            <small>제외할 goods_no</small>
            <b-form-textarea id="goodsNoExclude" :rows="2" v-model="form.goods_no_exclude" placeholder="제외할 goods_no를 입력해주세요"></b-form-textarea>
          </b-col>
          <b-col lg="3">
            <small>goods_id</small>
            <b-form-textarea id="goodsId" :rows="2" v-model="form.goods_id" placeholder="goods_id를 입력해주세요"></b-form-textarea>
          </b-col>
          <b-col lg="3">
            <small>제외할 goods_id</small>
            <b-form-textarea id="goodsId" :rows="2" v-model="form.goods_id_exclude" placeholder="제외할 goods_id를 입력해주세요"></b-form-textarea>
          </b-col>
          <b-col lg="3">
            <small>마스터 ID</small>
            <b-form-textarea :rows="2" v-model="form.gm_id" placeholder="gm_id를 입력해주세요"></b-form-textarea>
          </b-col>
          <b-col lg="3">
            <small>제외할 마스터 ID</small>
            <b-form-textarea :rows="2" v-model="form.gm_id_exclude" placeholder="제외할 gm_id를 입력해주세요"></b-form-textarea>
          </b-col>
          <b-col lg="3">
            <small>sku</small>
            <b-form-textarea :rows="2" v-model="form.matched_sku" placeholder="sku 입력해주세요"></b-form-textarea>
          </b-col>
          <b-col lg="3">
            <small>제외할 sku</small>
            <b-form-textarea :rows="2" v-model="form.matched_sku_exclude" placeholder="제외할 sku 입력해주세요"></b-form-textarea>
          </b-col>
          <b-col lg="3">
            <small>한 번에 가져올 상품 수</small><br/>
            <b-form-input id="limit" type="text" placeholder="한 번에 가져올 상품 수" v-model.number="form.limit"></b-form-input>
          </b-col>
        </b-row>

        <b-form-checkbox v-if="$R('BALAANEER')" class="mb-3" v-model="form.minimal" inline>필수정보만 가져옵니다(Update 등을 빠르게 하기 위해)</b-form-checkbox>
        <b-form-checkbox v-if="$R('BALAANEER')" class="mb-3" v-model="form.godo" inline>발란몰 open 정보를 가져옵니다</b-form-checkbox>
      </b-collapse>
      <b-button class="m-1" variant="primary" @click="list" :disabled="busy.list">검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner></b-button>
      <b-button class="m-1" variant="outline-success" v-b-toggle.collapse1>상세검색조건</b-button>
    </b-card>

    <div class="d-flex align-items-center">
      <small class="ml-1 text-muted">
        카탈로그에 매칭된 판매 중 상품만 조회되며, 데이터는 1시간 주기로 업데이트 됩니다. (단, 데이터 연동 중 카탈로그 매칭된 상품은 B 최저가가 - 으로 노출될 수 있습니다.)
      </small>
      <div class="ml-auto">
        <b-dropdown right variant="light" class="m-1">
          <template #button-content>
            <i class="fa fa-copy"></i>
          </template>
          <b-dropdown-item @click="copy('goods_no')">발란코드</b-dropdown-item>
          <b-dropdown-item @click="copy('goods_id')">Goods ID</b-dropdown-item>
          <b-dropdown-item @click="copy('goods_id', {withQuotes: true})">Goods ID(따옴표)</b-dropdown-item>
        </b-dropdown>
        <b-dropdown variant="success" class="m-1" :disabled="busy.xlsxDown">
          <template v-slot:button-content>
            <b-spinner class="mr-2" small v-if="busy.xlsxDown"></b-spinner>
            XLSX
          </template>
          <b-dropdown-item @click="preDown('normal')">상품별(기본)</b-dropdown-item>
<!--          <b-dropdown-item @click="preDown('opt')">옵션별</b-dropdown-item>-->
        </b-dropdown>
      </div>
    </div>

    <iframe name="xlsx_frame" style="display:none"></iframe>
    <form :action="getHost() + '/master/skuBiddingList/down'" ref="xlsx_form" method="POST" target="xlsx_frame" style="width:1px;height:1px;visibility:hidden">
      <input ref="json_data" type="hidden" name="j" />
    </form>

    <b-card>
      <b-row>
        <b-col ref="table">
          <c-table v-if="itemMode === 'list'" :table-data="items.list" :fields="fields" :perPage.sync="perPage" :isBusy="busy.list" :getMoreBusy="busy.listmore"
                   :hasMore="hasMore.list" :caption="items.list.length + (hasMore.list ? '+' : '') + ' 개'" @btn-clicked="btnAction"
                   @get-more="list(true)"></c-table>
        </b-col>
        <b-col v-if="showTab" cols="3" class="border-left">
          <i class="position-absolute fa fa-close pointer" style="right:20px; font-size:25px" @click="btnAction('', 'close')"></i>
          <template v-if="item.goods_no">
            <div class="pt-5">
              <div class="d-flex px-2" ref="goods">
                <div class="" style="flex: 0 0 120px">
                  <img style="width: 100%;" :src="bItems.catalog.img"/>
                </div>
                <div class="pl-2">
                  <h4 class="bold">{{bItems.catalog.brand_nm}}</h4>
                  {{bItems.catalog.goods_nm + (bItems.catalog.sku || '')}}
                </div>
              </div>
              <perfect-scrollbar v-if="bItems.items.length" class="p-2" style="max-width:600px;overflow-y:auto" :style="{maxHeight: bMaxHeight}">
                <div class="card" v-for="bItem in bItems.items">
                  <div class="card-body px-3 pt-3 pb-2">
                    <div class="d-flex justify-content align-items-center">
                      <div v-if="bItem.today_pick"><img :src="iconUrl.todayPickIconUrl"/></div>
                      <div v-else-if="bItem.oneday_delivery"><img :src="iconUrl.onedayIconUrl"/></div>
                      <div v-else-if="bItem.delivery_type==='국내'" class="d-flex justify-content align-items-center">
                        <div><img class="pr-1" :src="iconUrl.truckIconUrl"/></div>
                        <div>국내배송 |</div>
                      </div>
                      <div v-else-if="bItem.delivery_type==='해외'" class="d-flex justify-content align-items-center">
                        <div><img class="pr-1" :src="iconUrl.airplaneIconUrl" /></div>
                        <div>해외배송 |</div>
                      </div>
                      <div class="pl-1">{{bItem.expected_day}} 도착예정</div>
                    </div>
                    <div class="py-3 d-flex justify-content-between">
                      <div><span class="bold" style="font-size: 17px;">{{bItem.shop_id}}. {{bItem.shop}}</span></div>
                      <div class="d-flex justify-content-between align-items-center">
                        <div v-if="bItem.b_rank === 1" class="badge-light badge d-flex justify-content-between align-items-center">
                          <div><img :src="iconUrl.bPriceIconUrl"/></div>
                          <div>최저가</div>
                        </div>
                        <div><span class="bold pl-1" style="font-size: 17px;">{{$utils.comma(bItem.epPrice)}}원</span></div>
                      </div>
                    </div>
                    <div class="border-top pt-2 bold text-right" style="font-size: 12px">
                      옵션 별({{bItem.options[0].optnm}}) 재고현황
                      <img :class="`arrow_img${bItem.optionsOpen ? '_open' : ''}`" class="pointer"
                           @click="bItem.optionsOpen = !bItem.optionsOpen; $forceUpdate()"
                           src="https://i.balaan.io/mobile/img/icons/icon-arrow-sorting-up.png"/>
                    </div>
                  </div>
                  <div v-if="bItem.optionsOpen" class="card-footer" style="background-color:#f6f6f6;">
                    <div v-for="option in bItem.options" class="p-1 d-flex justify-content-between">
                      <div>
                        <span>{{option.Size}}</span>
                        <span class="small"> {{option.goodsPriceDiff > 0 ? `+ ${option.goodsPriceDiff}` : ''}}</span>
                      </div>
                      <span :style="option.stock >= 3 ? {color:'#626262'} : {color: '#bd083d'}">{{option.stock}}개</span>
                    </div>
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
          </template>
          <div v-else class="text-center mt-5">
            상품을 선택해주세요
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {CONFIRMED_COLUMNS, MAPPED_COLUMNS} from 'balaan_constants'
import * as momentBiz from 'moment-business-days';
import {getHost} from '@/shared/api'
import * as moment from 'moment-timezone';
import ListDataMixin from '../modules/ListDataMixin'

export default {
  name: 'MasterGroupList',
  title: '가격비교 (상품별)',
  mixins: [
    ListDataMixin
  ],
  data() {
    return {
      getHost,
      momentBiz,
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      syncForce: false,
      form: {
        search: '',
        shop: [],
        brand: [],
        category: [],
        orderby: 'goods_no',
        manual: 'ALL',
        goodsType: 'ALL',
        usedGrade: this.$C.USED_GRADE.map(e => e.value),
        sku_id: '',
        tot_stock: '',
        opt_stock: '',
        min: '',
        max: '',
        limit: 100,
        skip: 0,
        created_from: '',
        created_to: '',
        updated_from: '',
        updated_to: '',
        goods_no_from: '',
        goods_no_to: '',
        oneday_delivery: 'ALL',
        today_pick: 'ALL',
        orderMade: 'ALL',
        deliveryExtra: 'ALL',
        delivery_type: 'ALL',
        image_check: 'ALL',
        has_naver: false,
        naver_rank_from: '',
        naver_rank_to: '',
        naver_has_other_cnt: false,
        naver_other_cnt: '',
        naver_price_diff_per_from: '',
        naver_price_diff_per_to: '',
        options: false,
        optionSize: '',
        // optionStock: 1,
        major_color: this.$C.COLORS.map(e=>e.name),
        goods_no: '',
        goods_id: '',
        goods_id_exclude: '',
        goods_no_exclude: '',
        gm_id: '',
        gm_id_exclude: '',
        matched_sku: '',
        matched_sku_exclude: '',
        minimal: false,
        godo: false,
        b_price_diff_per_from: '',
        b_price_diff_per_to: '',
        // isBPrice: 'ALL'
      },
      collapse: {detail:false},
      lastBody: {list:{}},
      items: {list:[]},
      bItems: {items: [], catalog: {}},
      item: {},
      busy: {list: false, listmore: false, syncStockMulti: false, removeSoldoutOption: false, skuBidding: false},
      hasMore: {list:false},
      ac: {list: null}, // abortController
      title: '',
      itemAttr: {},
      imageIntent: 'default',
      fields: [
        'selected',
        {key: '_img60', label:'이미지', class: 'text-center'},
        {key: 'html2', label:`상품정보<br/><span class="badge badge-success">판매 가능수량</span>`, style:{maxWidth:'550px'}},
        {key: 'html6', label:`GM ID`, class: 'text-center'},
        {key: 'html3', label:'소비자가', class: 'text-center'},
        {key: '_actions3', label:'B 최저가', class: 'text-center', targets: [
            {html: '', style: {fontColor: "black"}},
            {html: '<br><span class="badge badge-light pointer">가격비교</span>', event: 'show_price'}
          ]},
        {key: 'html5', label:'EP송출가', class: 'text-center'},
      ],
      perPage: 20,
      itemMode: 'list',
      picGroup: 'ALL',
      picWidth: 175,
      picInfoTop: true,
      picInfoBottom: true,
      priceHtml: '',
      displayStatus: 'view',
      displayStatusRelay: true,
      displayStatusReason: '',
      today: momentBiz().format('YYYY-MM-DD'),
      iconUrl: {
        bPriceIconUrl: 'https://i.balaan.io/mobile/img/icons/icon-label-b-20.svg',
        truckIconUrl: 'https://i.balaan.io/mobile/img/icons/icon-truck-20.svg',
        airplaneIconUrl: 'https://i.balaan.io/mobile/img/icons/icon-airplane-20.svg',
        onedayIconUrl: 'https://i.balaan.io/mobile/img/icons/list-label-r-img.svg',
        todayPickIconUrl: 'https://i.balaan.io/mobile/img/icons/list-label-b-img.svg',
        arrowSortIconUrl: 'https://i.balaan.io/mobile/img/icons/icon-arrow-sorting-up.png',
        arrowRigthIconUrl: 'https://i.balaan.io/mobile/img/icons/icon-arrow-right-20.svg'
      },
      shopPresetOption: {excludeSpecialShop: true},
      port: +(localStorage.getItem('port') || 3030),
      showTab: true,
      bMaxHeight: '1500px',
    }
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    let meta = await this.$api.getMeta('shop,brand,category,holiday');

    meta.shop.forEach(s => {
      s.value = s.shop_id;
      s.label = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    }); // use_yn 무관 일단 정보는 필요
    // this.shop 에 use_yn = y 만 남는다면 shop preset 에서 use_yn = y 를 선택했을 때 갯수가 같아지는 문제가 있다.
    this.shop = meta.shop.filter(e=>e.use_yn==='y' && ![0, 204].includes(e.shop_id)).sort((a, b) => (a.use_yn === 'n' ? 10000 : 0) + a.shop_id - (b.use_yn === 'n' ? 10000 : 0) - b.shop_id);
    this.brand = meta.brand.map(e=>{
      return this.brandMap[e.brand_no] = {...e, value:e.brand_no, label:`${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a,b)=>a.label.localeCompare(b.label));

    this.category = meta.category.map(e=>{
      return this.categoryMap[e.category] = {...e, value:e.category, label:`${e.category} (${e.category_nm})`};
    }).sort((a,b)=>(a.value.length - b.value.length)*10 + a.value.localeCompare(b.value));

    if (Object.keys(this.$route.query).length) {
      Object.keys(this.$route.query).forEach(k=>{
        if (this.$route.query[k] != null) {
          let v = this.$route.query[k];
          if (~'tot_stock,opt_stock,min,max,goods_no_from,goods_no_to,naver_rank_from,naver_rank_to,naver_other_cnt,naver_price_diff_per_from,naver_price_diff_per_to,limit,skip'.split(',').indexOf(k)) {
            this.form[k] = +v;
          } else if (~'has_naver,naver_has_other_cnt,minimal,godo'.split(',').indexOf(k)) {
            this.form[k] = !(!v || v === 'false');
          } else if (k === '$shop') {
            let arr = v.split(',');
            this.form.shop = arr.map(e=>this.shopMap[e]);
          } else if (k === '$category') {
            let arr = v.split(',');
            this.form.category = arr.map(e=>this.categoryMap[e]);
          } else if (k === 'collapse.detail') {
            this.collapse.detail = !(!v || v === 'false');
          } else if (k === 'itemMode') {
            this.itemMode = v;
          } else if (~Object.keys(this.form).indexOf(k)) {
            this.form[k] = v;
          }
        }
      });
    }
    const no = this.$route.params.no;
    if (no) {
      this.form.search = no;
      this.form.display_status = 'ALL';
      await this.list();
      let item = this.items.list.filter(e=>e.goods_no === +no)[0];
      if (item) this.showModal({item});
      return;
    }
    this.list();
  },
  async beforeDestroy() {
    Object.values(this.ac).filter(e=>e).forEach(e=>e.abort());
    this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
  },
  watch: {
    itemMode() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    },
    picWidth() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    },
    perPage() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    }
  },
  methods: {
    toggleUsedGrade(grade) {
      if (!grade) {
        this.form.usedGrade = this.form.usedGrade.length === this.$C.USED_GRADE.length ? [] : this.$C.USED_GRADE.map(e => e.value);
      } else {
        this.form.usedGrade = this.$C.USED_GRADE.filter(e => e.value[0] === grade).map(e => e.value);
      }
    },
    async btnAction(row, event) {
      if (event === 'show_price') {
        this.item = row.item;
        this.showTab = true;
        this.getSkuBidding(this.item);
      } else if (event === 'close') {
        this.showTab = false;
      }
    },
    async list(more) {
      let form = this.form;
      if (form.limit >= 10000 && !form.minimal) return alert('10000개 이상을 검색할때는 필수정보만 가져오기를 선택해주세요');
      let shop = form.shop.length === this.shop.length ? [] : form.shop.map(e=>e.shop_id); // 전체 선택일 경우 비우기
      let brand = form.brand.map(e=>e.value);
      let category = form.category.map(e=>e.value);
      let goods_no = this.form.goods_no ? this.form.goods_no.trim().split(/\D+/g).map(e=>+e) : [];
      let goods_no_exclude = this.form.goods_no_exclude ? this.form.goods_no_exclude.trim().split(/\D+/g).map(e=>+e) : [];
      let goods_id = this.form.goods_id ? this.form.goods_id.trim().split(/\r?\n/g).map(e=>e.trim()) : [];
      let goods_id_exclude = this.form.goods_id_exclude ? this.form.goods_id_exclude.trim().split(/\r?\n/g).map(e=>e.trim()) : [];
      let gm_id = this.form.gm_id ? this.form.gm_id.trim().split(/\r?\n/g).map(e=>e.trim()) : [];
      let gm_id_exclude = this.form.gm_id_exclude ? this.form.gm_id_exclude.trim().split(/\r?\n/g).map(e=>e.trim()) : [];
      let matched_sku = this.form.matched_sku ? this.form.matched_sku.trim().split(/\r?\n/g).map(e=>e.trim()) : [];
      let matched_sku_exclude = this.form.matched_sku_exclude ? this.form.matched_sku_exclude.trim().split(/\r?\n/g).map(e=>e.trim()) : [];
      if (goods_no.length) {
        goods_no = goods_no.filter(e=>!~goods_no_exclude.indexOf(e));
        goods_no_exclude = [];
      }
      let body = {...form, shop, brand, category, goods_no, goods_no_exclude, goods_id, goods_id_exclude, gm_id, gm_id_exclude, matched_sku, matched_sku_exclude};

      let j = await this.$api.postTable(this, '/master/skuBiddingList', body, {more, fnAssign:this.assignTableData});
      this.bMaxHeight = (this.$refs.table ? Math.max(this.$refs.table.offsetHeight - 243, 500) : 500) + 'px';

      // 검색결과중 검색필드와 일치하는 데이터가 있다면 url 을 바꿔준다.
      if (j && j.list.filter(e=>e.goods_no+'' === this.form.search).length && location.hash === '#/goods') {
        history.replaceState(null, null, location.origin + '/#/goods/' + this.form.search);
      }
    },
    assignTableData(e) {
      if (!e.goods_id) {
        e.html2 = `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}</a> ` +
          this.makeGoodsNoLinkBadge(e.goods_no);
        return;
      } // minimal 인 경우
      if (e.category) {
        let cates = [];
        for (let i = 3; i <= e.category.length; i += 3) {
          if (e.category.substring(i - 3, i) === '001') cates.push('전체');
          else cates.push(this.categoryMap[e.category.substring(0, i)] && this.categoryMap[e.category.substring(0, i)].category_nm || '?');
        }
        e.cate = cates.join(' > ');
      } else if (e.brand_no !== 0) {
        // 개인결제창이 아닌 경우
        console.log('카테고리 없음 : ', e);
      }
      if (!e.consumer) e.consumer = e.price;
      // 배송유형 및 일자 확정
      let shop = this.shopMap[e.shop_id];
      if (shop) {
        if (shop.delivery_type !== 'both') {
          e.delivery_type = {abroad:'해외', domestic:'국내'}[shop.delivery_type];
          e.delivery_day = shop.delivery_day;
        } else {
          if (e.delivery_type === '국내') {
            e.delivery_day = shop.delivery_day;
          } else {
            e.delivery_day = shop.delivery_day_abroad || 21;
          }
        }
        e.delivery_str = e.delivery_type + (shop.logistics === 'direct' ? (e.delivery_type === '해외' && shop.pccc_feed === 'y' ? '구매대행' : '직배송') : '');
      } else {
        e.delivery_day = 21; // 이전부터 있던 하드코딩, 6일인 이유는?
        e.delivery_str = 'SHOP배송정보없음';
      }

      e.img = e.images && e.images[0] ? `https://i.balaan.io/${e.image_path}${e.images[0].thumb_200}` : e.img_urls[0];
      e.html2 = this.makeShopBadge(shop) + ' ' + this.makeBrandBadge(e.brand_no, e.brand_nm, {type: this.brandMap[e.brand_no].brand_type}) + ' ' +
        this.makeCategoryBadge(e.cate, e.category) + ' ' + this.makeGoodsNoLinkBadge(e.goods_no) + '<br/>' + e.goods_nm +
        `<br/>` + this.makeOptionsBadge(e.options, {lines: 2});

      e.html3 = e.consumer ? this.$utils.comma(e.consumer) : (e.price ? this.$utils.comma(e.consumer) : '');
      e._actions3 = `${e.epBPrice ? this.$utils.comma(e.epBPrice) : '-'}`;
      e.html5 = `${this.$utils.comma(e.epPrice || 0)}`;
      e.html6 = e.gm_id ? `<a href="/#/master/list/${e.gm_id}" class="badge badge-light" target="_blank">${e.gm_id} <i class="fa fa-external-link"></i></a>` : '';

      // 브랜드속성을 추가해준다.
      // https://www.notion.so/4198820b7eea40a09c6728a155e7d423?pvs=4#4cb38f7ed4114026a5aa8605e1ee034f
      e.brand_type_kr = (this.$C.BRAND_TYPE_MAP[this.brandMap[e.brand_no].brand_type] || {}).name || '';
    },
    async getSkuBidding(item) {
      let body = {goods_no: item.goods_no, gm_id: item.gm_id, matched_sku_id: item.matched_sku_id, delivery_day: item.delivery_day};
      let j = await this.$api.postJson('/master/skuBiddingGroup', body);
      if (j) {
        this.bItems.items = j.list;
        this.bItems.catalog = j.catalog;
        this.bItems.items.forEach(i=> {
          i.options.forEach(o=> {
            o.goodsPriceDiff = this.$utils.comma(o.goods_price - i.price);
          });
          i.price = this.$utils.comma(i.price);
          i.optionsOpen = true;
        })
      }
    },
    exclude(item) {
      this.form.goods_no_exclude += ' ' + item.goods_no;
    },
    picClick(e) {
      e.selected = !e.selected;
      this.$forceUpdate();
    },
    preDown(type) {
      const rows = [];
      const selectedItems = this.items.list;
      if (!selectedItems.length) return alert('다운받을 상품이 없습니다.');

      let base = 'goods_no,shop_id,shop,brand_no,brand_nm,brand_type_kr,goods_nm,category_nm,category,'
      const baseFields = {'b_rank_total': '매칭 상품수', 'brand_type_kr': '브랜드속성'};
      let name = 'Confirmed';

      if (type === 'normal') {
        base += 'tot_stock,consumer,epPrice,epBPrice,gm_id,b_rank_total';
        baseFields.epPrice = 'EP송출가';
        baseFields.epBPrice = 'B최저가';
        Object.entries(Object.assign(MAPPED_COLUMNS, CONFIRMED_COLUMNS)).forEach(([k,v])=>{
          if (base.includes(k)) baseFields[k] = v.name;
        });

        selectedItems.forEach(e=>{
          rows.push(e)
        });
      } else {
        // name += '_Option';
        // base += 'optName,optSize,optConsumer,optPrice,optStock,consumer,price,b_min_price,gm_id,b_rank_total';
        // Object.entries(Object.assign(MAPPED_COLUMNS, CONFIRMED_COLUMNS)).forEach(([k,v])=>{
        //   if (base.includes(k)) baseFields[k] = v.name;
        // });
        //
        // selectedItems.forEach(e=>{
        //   e.options.forEach(opt=>{
        //     rows.push({
        //       ...e,
        //       img_urls:e.img_urls.join('|'),
        //       optSize:opt.Size,
        //       b_min_price:e.b_min_price,
        //       optStock:opt.stock,
        //       optPrice: opt.goods_price,
        //       optConsumer: opt.goods_consumer
        //     });
        //   });
        // });
      }

      this.$refs.json_data.value = JSON.stringify({
        data: rows,
        keys: base.split(','),
        labels: baseFields,
        type: 'xlsx',
        name: `${name}_${moment(this.$utils.kstDT()).format('YYYY-MM-DD_hh_mm_ss')}.xlsx`});
      this.$refs.xlsx_form.submit();
    },
    copy(col, {withQuotes = false} = {}) {
      let selected = this.items.list.filter(e=>e.selected);
      if (!selected.length) return alert('복사할 상품을 선택해주세요');
      let res = this.$utils.copyToClipboard(selected.map(e => withQuotes ? `'${e[col].toString().replace(/'/g, "\\'")}'` : e[col]).join(withQuotes ? ',\n' : '\n'));
      if (res) this.$alertTop(`복사되었습니다`);
    },
  }
}
</script>

<style>
.arrow_img_open {
  transition: transform 0.5s;
  transform: rotate(180deg);
  height: 20px;
}

.arrow_img {
  transition: transform 0.5s;
  transform: rotate(360deg);
  height: 20px;
}
</style>
